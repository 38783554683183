
import AuthWelcome from '~/components/molecules/auth/AuthWelcome.vue'
import LoginTemplate from '~/components/templates/LoginTemplate.vue'

export default {
  components: { AuthWelcome, LoginTemplate },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
  },
}
