export const DEFAULT_PROFILE_PICTURE_URL = '/svg/default-profile-pic-border.svg'

/**
 * Whether if the email of a player can be updated.
 * @param {Object} player the player.
 * @returns {Boolean}
 */
export const isEmailUpdatable = (player) =>
  player &&
  player.has_local_credentials &&
  !player.has_facebook_credentials &&
  !player.has_apple_credentials
