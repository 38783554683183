
import ClubCreateLink from '~/components/molecules/clubs/ClubCreateLink.vue'
import ClubPicture from '~/components/molecules/clubs/ClubPicture.vue'

export default {
  components: { ClubCreateLink, ClubPicture },

  computed: {
    /**
     * The current selected item.
     * @returns {Object}
     */
    currentItem() {
      return this.$store.getters['clubs/getSelected']
    },
    /**
     * The clubs.
     * @returns {Array<Object>}
     */
    items() {
      return this.$store.getters['clubs/getList']
    },
    /**
     * Whether if screen resolution is mobile.
     * @returns {Boolean}
     */
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
  },

  methods: {
    setDialogJoinClubOpen() {
      if (this.isMobile) {
        this.$store.commit('app/setSidebarOpen', false)
      }

      this.$store.commit('clubs/setIsDialogJoinClubOpen', true)
    },
    /**
     * Redirect to the selected club.
     * @param {Object} club the club to redirect to.
     */
    redirectTo(club) {
      // redirect to the default club page when the current page is not specific to a club.
      if (this.$route.name.includes('clubs-club_id')) {
        const allRoutes = this.$router.getRoutes()
        const currentRoute = allRoutes.find(
          (route) => route.name === this.$route.name
        )

        /**
         * Try to redirect to the base url.
         * Let's say user is on route `/clubs/:club_id/events/match/:event_id`, redirect to `/clubs/:club_id/events`.
         */
        const basePath = currentRoute
          ? currentRoute.path.split('/').slice(0, 4).join('/')
          : '/' // if no current route (should not happen), redirect to `/`
        const nextRoute = allRoutes.find((route) => route.path === basePath)

        this.$router.push({
          name: nextRoute ? nextRoute.name : this.$route.name,
          params: {
            ...(nextRoute ? {} : this.$route.params), // don't include other params if redirecting to base path
            club_id: club.id,
          },
        })
      } else {
        this.$router.push({
          name: 'clubs-club_id',
          params: { club_id: club.id },
        })
      }
    },
  },
}
