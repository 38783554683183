import { isEmpty } from '~/utils/data'
import { request } from '~/utils/request'

/* eslint-disable camelcase */
/**
 * Remove values that should not be sent to the server when creating/updating a club.
 * @param {Object} data the club data.
 * @returns {Object}
 */
const sanitizeClub = ({
  name,
  club_picture_id,
  code,
  is_club_pic_uploaded,
  legal_status,
  sport_id,
  street_address,
  payment_instructions,
  timezone_id,
  town_id,
}) => ({
  name,
  club_picture_id,
  code: code || '',
  is_club_pic_uploaded,
  legal_status,
  sport_id,
  street_address,
  payment_instructions,
  timezone_id,
  town_id,
})
/* eslint-enable camelcase */

/**
 * Default state.
 * @returns {Object}
 */
export const state = () => ({
  clubToJoin: null,
  isDialogJoinClubOpen: false,
  list: {
    fetchedOnce: false,
    ids: [],
    data: {},
  },
  paymentClub: null,
  selected: null,
})

export const actions = {
  /**
   * List all clubs.
   * @returns {Promise}
   */
  async fetchList({ commit }) {
    const { data } = await request(this, 'club').get('/clubs')
    commit('setList', data)
    return data
  },

  /**
   * Retrieve a club by its ID.
   * @param {Object} app the app.
   * @param {Number} id the club unique identifier.
   * @returns {Promise<Object>}
   */
  async fetchOne(_, { id }) {
    const { data } = await request(this, 'club').get('/clubs/{id}', { id })
    return data
  },

  /**
   * Retrieve a club by its code.
   * @param {Number} code the code to find a club.
   * @returns {Promise<Object>} the club.
   */
  async fetchOneByCode(_, { code }) {
    const { data } = await request(this, 'club').get('/clubs/code/{code}', {
      code,
    })
    return data
  },

  /**
   * Fetch club statistics.
   * @param {Number} clubId the club unique identifier.
   * @returns {Promise<Object>}
   */
  async fetchStatistics(_, clubId) {
    const { data } = await request(this, 'club').get(
      '/clubs/{clubId}/statistics',
      { clubId }
    )
    return data
  },

  /**
   * Create a club.
   * @param {Function} commit a function to commit data in the store.
   * @param {Object} values the club values.
   * @returns {Promise<Object>} the created club.
   */
  async create({ commit, dispatch }, values) {
    let payload = sanitizeClub(values)

    if (values.club_picture) {
      const imageData = await dispatch(
        'files/uploadClubPicture',
        { image: values.club_picture },
        { root: true }
      )

      payload = { ...payload, ...imageData }
    }

    const { data } = await request(this, 'club').post('/clubs', null, payload)
    commit('addClubToList', data)

    return data
  },

  /**
   * Delete a club.
   * If the club is the current selected, it will be set to empty.
   * @param {Function} commit a function to commit data in the store.
   * @param {Number} id the club unique identifier.
   * @param {Boolean} deleteTeams whether to delete the teams with the club.
   * @returns {Promise}
   */
  async delete({ commit }, { id, deleteTeams }) {
    const result = await request(this, 'club').delete(
      '/clubs/{id}',
      { id },
      { deleteTeams: Number(!!deleteTeams) }
    )

    commit('removeClubFromList', id)

    return result
  },

  /**
   * Update a club.
   * @param {Object} values the club values.
   * @returns {Promise<Object>} the updated club.
   */
  async update({ commit, state, dispatch }, values) {
    let payload = sanitizeClub(values)

    if (values.club_picture) {
      const imageData = await dispatch(
        'files/uploadClubPicture',
        { image: values.club_picture },
        { root: true }
      )

      payload = { ...payload, ...imageData }
    }

    const { data } = await request(this, 'club').put(
      '/clubs/{id}',
      { id: values.id },
      payload
    )

    commit('addClubToList', data)
    if (state.selected && state.selected.id === data.id) {
      commit('setSelected', data)
    }

    return data
  },

  /**
   * Request to join a club.
   * @param {Number} clubId the club unique identifier.
   * @param {Number} playerId the player unique identifier.
   * @returns {Promise<Object>} the created grant admin request.
   */
  async joinClub(_, { clubId, playerId }) {
    const { data } = await request(this, 'club').post(
      '/club-grant-admin-request',
      null,
      {
        club_id: clubId,
        player_id: playerId,
      }
    )

    return data
  },

  /**
   * Update a club grant admin request.
   * @param {Number} id the grant request identifier.
   * @param {Number} clubId the club unique identifer.
   * @param {Number} playerID the player identifier.
   * @param {String} status the new status for the grant request. Can be `APPROVED` or `DECLINED`.
   * @returns {Promise<Object>}
   */
  async updateClubGrantAdminRequest(_, { id, clubId, playerId, status }) {
    const { data } = await request(this, 'club').put(
      '/club-grant-admin-request/{clubGrantAdminRequestId}',
      { clubGrantAdminRequestId: id },
      {
        club_id: clubId,
        player_id: playerId,
        status,
      }
    )

    return data
  },

  /**
   * Update a club membership.
   * @param {Number} id the membership unique identifier.
   * @param {Object} membership the membership to update.
   * @returns {Promise<Object>}
   */
  async updateMembership(_, { id, ...membership }) {
    const { data } = await request(this, 'club').put(
      '/memberships/{membershipId}',
      { membershipId: id },
      membership
    )

    return data
  },

  /**
   * Update many club memberships at once.
   * The same properties will ba applied to all memberships.
   * @param {Array<Number>} ids the memberships identifiers.
   * @param {Object} membershipData the membership data.
   * @returns {Promise<Array<Object>>}
   */
  async updateMemberships(_, { ids, ...membershipData }) {
    const { data } = await request(this, 'club').put(
      '/memberships/bulk-update',
      {},
      { ...membershipData, membership_ids: ids }
    )

    return data
  },

  /**
   * Delete a pending club grant admin request.
   * @param {Number} id the request identifier.
   * @returns {Promise}
   */
  async deleteClubGrantAdminRequest(_, { id }) {
    const { data } = await request(this, 'club').delete(
      '/club-grant-admin-request/{clubGrantAdminRequestId}',
      {
        clubGrantAdminRequestId: id,
      }
    )

    return data
  },

  /**
   * Remove a player from a club.
   * @param {Number} id the membership unique identifier.
   * @returns {Promise}
   */
  async deleteClubMembership(_, { id }) {
    return await request(this, 'club').delete('/memberships/{membershipId}', {
      membershipId: id,
    })
  },

  /**
   * Remove many players from a club.
   * @param {Array} membershipsIds the memberships unique identifiers.
   * @returns {Promise}
   */
  async deleteClubMemberships(_, { membershipsIds }) {
    const { data } = await request(this, 'club').post(
      '/memberships/bulk-delete',
      {},
      membershipsIds
    )
    return data
  },

  /**
   * Try to remove a club membership for a player.
   * The club membership will be updated or deleted, whether if the player is member of teams linked to the club.
   * @returns {Promise<Object|null>}
   */
  async downgradeClubMembership(_, { id }) {
    const { data } = await request(this, 'club').delete(
      '/memberships/{membershipId}/downgrade',
      { membershipId: id }
    )
    return data
  },
}

export const getters = {
  /**
   * Get the list of clubs.
   * @param {Object} state the current state.
   * @returns {Array<Object>} a list of clubs.
   */
  getList(state) {
    return state.list.ids.map((id) => state.list.data[id])
  },

  /**
   * Get the club for which a membership request should be sent after the user registration.
   * @param {Object} state the current state.
   * @returns {Object} the club, if any, else `null`.
   */
  getClubToJoin(state) {
    return state.clubToJoin
  },

  /**
   * Get the current club used during pay-in, if any.
   * @param {Object} state the current state.
   * @returns {Object} the club, if any, else `null`.
   */
  getPaymentClub(state) {
    return state.paymentClub
  },

  /**
   * Get the current selected club, if any.
   * @param {Object} state the current state.
   * @returns {Object} the club, if any, else `null`.
   */
  getSelected(state) {
    return state.selected
  },

  /**
   * Check if clubs list has been loaded at least once.
   * @param {Object} state the current state.
   * @returns {Boolean}
   */
  isListFetchedOnce(state) {
    return state.list.fetchedOnce
  },

  /**
   * Whether if the <ClubJoinDialog /> should be open or not.
   * @param {Object} state the current state.
   * @returns {Boolean}
   */
  isDialogJoinClubOpen(state) {
    return state.isDialogJoinClubOpen
  },
}

export const mutations = {
  /**
   * Add a club to the list, or replace it if already in.
   * @param {Object} state the current state.
   * @param {Object} club the club to add.
   */
  addClubToList(state, club) {
    if (!state.list.ids.includes(club.id)) {
      state.list.ids.push(club.id)
    }

    state.list.data[club.id] = club
  },

  /**
   * Partially update a club.
   * @param {Object} state the current state.
   * @param {Object} club the club data to update. This object must contain an `id` property.
   */
  patchClub(state, data = {}) {
    const { id, ...rest } = data
    const club = state.list.data[id]

    if (club) {
      state.list.data[club.id] = { ...club, ...rest }

      if (state.selected?.id === club.id) {
        state.selected = { ...club, ...rest }
      }

      if (state.paymentClub?.id === club.id) {
        state.paymentClub = { ...club, ...rest }
      }
    }
  },

  /**
   * Remove a club from the list.
   * @param {Object} state the current state.
   * @param {Number} clubId the ID of the club to remove.
   */
  removeClubFromList(state, clubId) {
    const index = state.list.ids.indexOf(clubId)
    if (index > -1) {
      state.list.ids.splice(index, 1)
    }
  },

  /**
   * Set default value in state club.
   * @param {Object} currentState the current state.
   */
  resetState(currentState) {
    const { list, selected } = state()
    currentState.list = list
    currentState.selected = selected
  },

  /**
   * Set the state of isDialogJoinClubOpen.
   * @param {Object} state the current state.
   * @param {Boolean} value the new dialog state
   */
  setIsDialogJoinClubOpen(state, value) {
    state.isDialogJoinClubOpen = value
  },

  /**
   * Set the list of clubs.
   * @param {Object} state the current state.
   * @param {Array<Object>} clubs a list of clubs.
   */
  setList(state, clubs) {
    const list = Array.isArray(clubs) ? clubs : []
    list.sort((a, b) => a.name.localeCompare(b.name))
    const { ids, data } = list.reduce(
      (acc, club) => ({
        ids: [...acc.ids, club.id],
        data: { ...acc.data, [club.id]: club },
      }),
      { ids: [], data: {} }
    )

    state.list.fetchedOnce = true
    state.list.ids = ids
    state.list.data = data
  },

  /**
   * Set a club to use during a player pay-in.
   * @param {Object} state the current state.
   * @param {Object} club the club to use.
   */
  setPaymentClub(state, club) {
    state.paymentClub = club
  },

  /**
   * Set a club as selected.
   * @param {Object} state the current state.
   * @param {Object} club the club to set as selected.
   */
  setSelected(state, club) {
    if (isEmpty(club)) {
      state.selected = null
    } else {
      state.selected = club
    }
  },

  /**
   * Set a club as club to join.
   * @param {Object} state the current state.
   * @param {Object} club the club to set as club to join.
   */
  setClubToJoin(state, club) {
    if (isEmpty(club)) {
      state.clubToJoin = null
    } else {
      state.clubToJoin = club
    }
  },
}
