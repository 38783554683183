/* eslint-disable no-console */
import jwtDecode from 'jwt-decode'
import { LOCALE_ID_TO_CODE } from '~/models/enums'
import { ExpiredAuthSessionError, RefreshScheme } from '~auth/runtime'

/**
 * Get property defined by dot notation in string.
 * Based on  https://github.com/dy/dotprop (MIT)
 * @see https://github.com/nuxt-community/auth-module/blob/dev/src/utils/index.ts
 *
 * @param  {Object} holder   Target object where to look property up
 * @param  {string} propName Dot notation, like 'this.a.b.c'
 * @return {*}          A property value
 *
 */
const getProp = (holder, propName) => {
  if (!propName || !holder || typeof holder !== 'object') {
    return holder
  }

  if (propName in holder) {
    return holder[propName]
  }

  const propParts = Array.isArray(propName)
    ? propName
    : (propName + '').split('.')

  let result = holder
  while (propParts.length && result) {
    result = result[propParts.shift()]
  }

  return result
}

/**
 * Remove all undefined properties from an object.
 * @see https://github.com/nuxt-community/auth-module/blob/dev/src/utils/index.ts
 *
 * @param {Object} obj the object to clean.
 * @returns {Object}
 */
export function cleanObj(obj) {
  for (const key in obj) {
    if (obj[key] === undefined) {
      delete obj[key]
    }
  }

  return obj
}

export default class TeamPulseAuthScheme extends RefreshScheme {
  /**
   * Override method fetching authenticated user.
   * @see https://auth.nuxtjs.org/guide/scheme#creating-your-own-scheme
   * @see https://github.com/nuxt-community/auth-module/blob/51f0ebf183378ce9c0f932403cb0c6fe5fdef417/src/schemes/local.ts
   *
   * @param {HTTPRequest} endpoint the authentication endpoint.
   * @returns {Promise}
   */
  fetchUser(endpoint) {
    // Token is required but not available
    if (!this.check().valid) {
      return Promise.resolve()
    }

    // User endpoint is disabled
    if (!this.options.endpoints.user) {
      this.$auth.setUser({})
      return Promise.resolve()
    }

    // ================ CUSTOM PART ================
    // decode token to retrieve authenticated user ID
    // if token is not set, this code is unreachable
    const token = this.token.sync()
    const decodedToken = jwtDecode(token)
    const requestOptions = Object.assign({}, this.options.endpoints.user, {
      url: this.options.endpoints.user.url.replace('{id}', decodedToken.id),
    })
    // =============================================

    // Try to fetch user and then set
    return this.$auth
      .requestWith(this.name, endpoint, requestOptions)
      .then((response) => {
        const userData = getProp(response.data, this.options.user.property)

        if (!userData) {
          const error = new Error(
            `User Data response does not contain field ${this.options.user.property}`
          )
          return Promise.reject(error)
        }

        this.$auth.setUser(userData)

        // ================ CUSTOM PART ================
        const locale = userData.locale
          ? userData.locale.locale_code
          : LOCALE_ID_TO_CODE[userData.locale_id]
        if (locale) {
          try {
            this.$auth.ctx.$i18nHelper.setLocale(locale)
          } catch (error) {
            // don't block user if context not available (should always be available)
            console.error(error)
          }
        }
        // =============================================

        return response
      })
      .catch((error) => {
        this.$auth.callOnError(error, { method: 'fetchUser' })
        return Promise.reject(error)
      })
  }

  /**
   * Extend method resetting user authentication.
   * Inject required properties.
   * @see https://github.com/nuxt-community/auth-module/blob/51f0ebf183378ce9c0f932403cb0c6fe5fdef417/src/schemes/local.ts
   *
   * @param {HTTPRequest} endpoint the authentication endpoint.
   * @returns {Promise}
   */
  async logout(endpoint) {
    // ================ CUSTOM PART ================
    if (!endpoint) {
      endpoint = { data: {} }
    }

    endpoint.data = {
      ...endpoint.data,
      app_version: this.options.appVersion,
      refresh_token: this.refreshToken.get(), // mandatory to use endpoint
      user_agent: window.navigator.userAgent,
    }

    return await super.logout(endpoint)
    // =============================================
  }

  /**
   * Override method using refresh token to generate a new access token.
   * @see https://auth.nuxtjs.org/guide/scheme#creating-your-own-scheme
   * @see https://github.com/nuxt-community/auth-module/blob/51f0ebf183378ce9c0f932403cb0c6fe5fdef417/src/schemes/refresh.ts
   *
   * @returns {Promise}
   */
  refreshTokens() {
    // Refresh endpoint is disabled
    if (!this.options.endpoints.refresh) {
      return Promise.resolve()
    }

    // Token and refresh token are required but not available
    if (!this.check().valid) {
      return Promise.resolve()
    }

    // Get refresh token status
    const refreshTokenStatus = this.refreshToken.status()

    // Refresh token is expired. There is no way to refresh. Force reset.
    if (refreshTokenStatus.expired()) {
      this.$auth.reset()

      throw new ExpiredAuthSessionError()
    }

    // Delete current token from the request header before refreshing, if `tokenRequired` is disabled
    if (!this.options.refreshToken.tokenRequired) {
      this.requestHandler.clearHeader()
    }

    const endpoint = {
      data: {
        client_id: undefined,
        grant_type: undefined,
      },
    }

    // Add refresh token to payload if required
    if (this.options.refreshToken.required && this.options.refreshToken.data) {
      endpoint.data[this.options.refreshToken.data] = this.refreshToken.get()
    }

    // Add client id to payload if defined
    if (this.options.clientId) {
      endpoint.data.client_id = this.options.clientId
    }

    // Add grant type to payload if defined
    if (this.options.grantType) {
      endpoint.data.grant_type = 'refresh_token'
    }

    // ================ CUSTOM PART ================
    /**
     * Don't inject unwanted properties, the server will reject the request.
     */
    endpoint.data = {
      ...endpoint.data,
      // properties accepted by the refresh token endpoint
      app_version: this.options.appVersion,
      user_agent: window.navigator.userAgent,
    }
    // =============================================

    cleanObj(endpoint.data)

    // Make refresh request
    return this.$auth
      .request(endpoint, this.options.endpoints.refresh)
      .then((response) => {
        // Update tokens
        this.updateTokens(response, { isRefreshing: true })
        return response
      })
      .catch((error) => {
        this.$auth.callOnError(error, { method: 'refreshToken' })

        // ================ CUSTOM PART ================
        // do not disconnect user if error is not a 401
        return error.response?.status === 401
          ? Promise.reject(error)
          : Promise.resolve()
        // =============================================
      })
  }
}
