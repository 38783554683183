export const PDF_TYPES = ['application/pdf']
export const DOCUMENT_TYPES = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.oasis.opendocument.text',
]
export const SPREADSHEET_TYPES = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.oasis.opendocument.spreadsheet',
]
export const PRESENTATION_TYPES = [
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.oasis.opendocument.presentation',
]

export const ALLOWED_MESSAGING_DOCUMENTS_TYPES = [
  ...PDF_TYPES,
  ...DOCUMENT_TYPES,
  ...SPREADSHEET_TYPES,
  ...PRESENTATION_TYPES,
  'image/*',
]

export const ALLOWED_POST_DOCUMENT_TYPES = [
  ...PDF_TYPES,
  ...DOCUMENT_TYPES,
  ...SPREADSHEET_TYPES,
  ...PRESENTATION_TYPES,
]
export const POST_IMAGE_EXTENSION = 'jpg'
export const ALLOWED_POST_IMAGE_TYPES = [
  'image/jpeg',
  // 'video/*', // TODO: not yet available
]

export const ALLOWED_BANK_DOCUMENT_TYPES = [
  /**
   * @see https://mangopay.com/docs/concepts/users/verification/id-best-practices
   */
  ...PDF_TYPES,
  'image/jpg',
  'image/jpeg',
  'image/png',
]

export class File {
  /**
   * Whether if a type of file can be uploaded or not.
   * @param {String} mimeType the file mime type.
   * @returns {Boolean}
   */
  static isMessagingUploadMimeTypeAllowed(mimeType) {
    if (!mimeType) {
      return false
    }

    if (mimeType.startsWith('image/')) {
      return true
    }

    return ALLOWED_MESSAGING_DOCUMENTS_TYPES.includes(mimeType)
  }

  /**
   * Whether if a type of file can be uploaded or not.
   * @param {String} mimeType the file mime type.
   * @returns {Boolean}
   */
  static isPostUploadMimeTypeAllowed(mimeType) {
    if (!mimeType) {
      return false
    }

    if (
      mimeType.startsWith('image/') /* TODO || mimeType.startsWith('video/') */
    ) {
      return true
    }

    return ALLOWED_POST_DOCUMENT_TYPES.includes(mimeType)
  }
}
